//加密
import CryptoJS from "crypto-js";
//引用AES源码js

var key = CryptoJS.enc.Utf8.parse("xiaobao202201234");

// 加密函數
export function Encrypt(word) {
  var encryptedData = CryptoJS.AES.encrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encryptedData;
}
// 解密函數
export function Decrypt(word) {
  let text = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);

  return text;
}
